import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrorMessage, fetchAuth } from '../../redux/reducers/auth'
import { AppDispatch } from '../../redux/store'

import styles from './Auth.module.css'
import { fetchInit } from '../../redux/reducers/init'
import { Link } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { initSelector } from '../../redux/selectors/init-selector'

import classNames from 'classnames'
import { authSelector } from '../../redux/selectors/auth-selector'

function Auth() {
  const dispatch = useDispatch<AppDispatch>()
  const [retrievedObject, setRetrievedObject] = useState<any>(null)
  const { initCompany, isPending } = useSelector(initSelector)
  const { errorMessage } = useSelector(authSelector)

  const [deleteLoader, setDeleteLoader] = useState(true)

  //

  useEffect(() => {
    document.cookie = 'auth=; Max-Age=-1;'
    dispatch(fetchInit())
  }, [])

  const [formFields, setFormFields] = useState({
    login: '',
    password: '',
    remember: false,
  })

  const onChangeRemember = (e: any) => {
    setFormFields({ ...formFields, remember: e.target.checked })
  }

  const authHandler = () => {
    dispatch(fetchAuth(formFields))
  }

  const LoadingPage = () => (
    <div
      id="page_loading"
      className={classNames(
        styles.pageLoading,
        !isPending ? styles.fadeOut : ''
      )}
    >
      {' '}
      <div className={styles.loadingImg}>
        <span
          style={{
            background: "url('/img/company/logo.svg') center center no-repeat",
          }}
          className={styles.logoCirc}
        />
        <br />
        <div className={styles.loadingTxt} style={{ textAlign: 'center' }}>
          Loading..
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    if (initCompany) {
      setRetrievedObject(initCompany)
    }
  }, [initCompany])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (isPending) {
      setDeleteLoader(true)
    } else {
      timeoutId = setTimeout(() => {
        setDeleteLoader(false)
      }, 1000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isPending])

  const handleInputEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      dispatch(clearErrorMessage())
    }
    setFormFields({ ...formFields, login: e.target.value })
  }

  const handleInputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      dispatch(clearErrorMessage())
    }
    setFormFields({
      ...formFields,
      password: e.target.value,
    })
  }

  return (
    <>
      <div
        className={`${styles.block} ${styles.block1} ${styles.bgMonoh} ${styles.bgBlue}`}
        style={{ backgroundImage: `url(${retrievedObject?.background})` }}
      >
        <div className={`container ${styles.container}`}>
          <div className="row">
            {deleteLoader && <LoadingPage />}
            <div className="col-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
              <div className="mb-5" style={{ textAlign: 'center' }}>
                <a href="login.php" className={`${styles.logo} d-block mb-2`}>
                  <img
                    width="240"
                    src={retrievedObject?.logoWhite?.original}
                    alt="logo"
                  />
                </a>
                <div className="mb-0">
                  <b className={styles.b}>
                    Logistics&nbsp; Management&nbsp; System
                  </b>
                </div>
              </div>

              <div
                className={`${styles.blockWhite} ${styles.borderRadius20}`}
                style={{ textAlign: 'left' }}
              >
                <h1
                  className={`${styles.h2} ${styles.wow} ${styles.fadeIn}`}
                  style={{ textAlign: 'center' }}
                >
                  Sign In
                </h1>
                <form
                  method="get"
                  id="login_form"
                  name="login_form"
                  action="account-reports.php"
                  className={`mb-5 ${styles.form}`}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input type="hidden" name="action" value="login" />

                  <div className={styles.formLine}>
                    <small className={styles.small}>E-mail / Login</small>
                    <input
                      required
                      type="text"
                      name="text"
                      placeholder="Enter your E-mail or Login"
                      onChange={(e) => handleInputEmail(e)}
                      value={formFields.login}
                    />
                  </div>
                  <div className={styles.formLine}>
                    <small className={styles.small}>Password</small>
                    <input
                      required
                      type="password"
                      name="pass"
                      placeholder="Enter your password"
                      className="mb-2"
                      onChange={(e) => handleInputPassword(e)}
                      value={formFields.password}
                    />
                    <div className="row">
                      <div
                        className={`${styles.small} col-6 col-sm-6`}
                        style={{ textAlign: 'left' }}
                      >
                        <input
                          id="remember"
                          type="checkbox"
                          onChange={(e) => onChangeRemember(e)}
                          checked={formFields.remember}
                        />
                        <label htmlFor="remember" className={styles.label}>
                          Remember Me
                        </label>
                      </div>
                      <div
                        className={`${styles.small} col-6 col-sm-6`}
                        style={{ textAlign: 'right' }}
                      >
                        <Link to={PATH.RESET_PASSWORD}>
                          <b className={classNames(styles.b, styles.small)}>
                            Forgot password?
                          </b>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'center' }}
                    className={classNames(styles.redError, styles.small)}
                  >
                    {errorMessage}
                  </div>
                  <div className="pt-4" style={{ textAlign: 'center' }}>
                    <input
                      onClick={authHandler}
                      type="submit"
                      className={`${styles.btn} d-block d-sm-inline-block`}
                      value="Sign In"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Auth
