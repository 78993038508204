import React, { useState } from 'react'

import styles from './ChangeEmailModal.module.css'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import classNames from 'classnames'
import {
  fetchChangeEmail,
  setIsVisibleModalChangeEmail,
} from '../../../redux/reducers/auth'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'

function ChangeEmailModal() {
  const dispatch = useDispatch<AppDispatch>()

  const [email, setEmail] = useState('')

  useClearBodyNoScrollClass()

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalChangeEmail(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`mb-5 ${styles.h3}}`)}
            >
              Change E-mail
            </h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <p className={styles.p}>
                We will send a confirmation link to your new email:
              </p>
              <div className={styles.formLine}>
                <input
                  className={classNames(`mb-2 ${styles.input}}`)}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter new E-mail"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 mt-1 mb-1">
                  <button
                    onClick={() => dispatch(fetchChangeEmail(email))}
                    className={`${styles.btn} ${styles.btnWide} ${styles.btnSmall}`}
                  >
                    Confirm
                  </button>
                </div>
                <div className="col-12 col-sm-6 mt-1 mb-1">
                  <div
                    onClick={() =>
                      dispatch(setIsVisibleModalChangeEmail(false))
                    }
                    className={`${styles.btn} ${styles.btnWide} ${styles.btnSmall} ${styles.btnBlueOutline}`}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeEmailModal
