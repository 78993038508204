export const getRole = () => {
  const item = localStorage.getItem('userInfo')
  const userInfo = item ? JSON.parse(item) : {}

  return {
    isAdmin: userInfo.role === 'admin',
    isOwner: userInfo.role === 'owner',
    isDriver: userInfo.role === 'driver',
    isAssistant: userInfo.role === 'assistant',
    hasRole: !!item,
  }
}
