import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCars,
  fetchCarsForAdmins,
  setCurrentCar,
  setIsVisibleDeleteModalCar,
  setIsVisibleModalCar,
} from '../../redux/reducers/cars'
import { AppDispatch } from '../../redux/store'
import { carsSelector } from '../../redux/selectors/cars-selector'
import HeaderMain from '../../components/header-main/HeaderMain'

import styles from './Cars.module.css'
import classNames from 'classnames'
import { getRole } from '../../utils/getRole'
import { companySelector } from '../../redux/selectors/company-selector'
import Spinner from '../../components/spinner/Spinner'

interface CarsProps {
  id: number
  name: string
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
}
const { isOwner, isAdmin } = getRole()
function Cars() {
  const dispatch = useDispatch<AppDispatch>()
  const { cars, isPendingGetCars } = useSelector(carsSelector)
  const { currentCompany } = useSelector(companySelector)

  const handleEditCar = (item: CarsProps) => {
    dispatch(setCurrentCar(item))
    dispatch(setIsVisibleModalCar(true))
  }

  const handleDeleteCar = (item: CarsProps) => {
    dispatch(setCurrentCar(item))
    dispatch(setIsVisibleDeleteModalCar(true))
  }

  useEffect(() => {
    if (isOwner) {
      dispatch(fetchCars())
    }
    if (isAdmin && currentCompany) {
      dispatch(fetchCarsForAdmins(currentCompany.id))
    }
  }, [currentCompany?.id, dispatch])

  const isVisibleAddButton = isAdmin
    ? currentCompany?.id
      ? true
      : false
    : true

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          {/* ADD CAR */}
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10  offset-xl-2 col-xl-8">
              <div className="mb-3" style={{ textAlign: 'left' }}>
                {isVisibleAddButton && (
                  <a
                    onClick={() => {
                      dispatch(setCurrentCar(null))
                      dispatch(setIsVisibleModalCar(true))
                    }}
                    className={`${styles.btn} ${styles.btnSmall}`}
                  >
                    + Add car
                  </a>
                )}
              </div>
              <>
                {isPendingGetCars ? (
                  <Spinner />
                ) : (
                  cars.map((item) => (
                    <div
                      key={item.id}
                      className={classNames(
                        `pl-3 pr-3 ${styles.bgWhite} pt-2 pb-2 ${styles.border5} ${styles.bxShadow} mb-3 ${styles.disactive}`
                      )}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-3">
                          <a className={styles.carImg}>
                            <img
                              onClick={() => handleEditCar(item)}
                              src={item.image.md || 'img/cars/no-car-photo.svg'}
                            />
                          </a>
                        </div>
                        <div className="col-8 col-sm-6 mt-1">
                          <div className={`${styles.fontBig} mb-2`}>
                            <a onClick={() => handleEditCar(item)}>
                              <b>{item.name}</b>
                            </a>
                          </div>

                          {item.active ? (
                            <div className={styles.small}>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconVerified2,
                                  styles.icon20
                                )}
                              />
                              <span> Active</span>
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                styles.small,
                                styles.colorRed
                              )}
                            >
                              Disabled
                            </div>
                          )}
                        </div>
                        <div
                          className="col-4 col-sm-3 mt-1"
                          style={{ textAlign: 'right' }}
                        >
                          <div
                            onClick={() => handleEditCar(item)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall,
                              styles.btnIcon,
                              styles.btnBlueOutline
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconEdit
                              )}
                            />
                          </div>
                          <div
                            onClick={() => handleDeleteCar(item)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall,
                              styles.btnIcon,
                              styles.btnOutline
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconDeleteRed
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cars
