import { useEffect, useState } from 'react'
import styles from './Header.module.css'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { logout } from '../../redux/reducers/auth'
import { getRole } from '../../utils/getRole'
import classNames from 'classnames'
import {
  setSelectedDrivers,
  setSelectedTrucks,
} from '../../redux/reducers/report-filter'
import { companySelector } from '../../redux/selectors/company-selector'

const LINKS = [
  // {
  //     path: PATH.DASHBOARD,
  //     iconStyle: "icon icon-home-white",
  //     title: "Dashboard",
  // },
  {
    path: PATH.COMPANIES,
    iconStyle: 'iconFlagWhite',
    title: 'Companies',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Reports',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.MY_REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'My Reports',
    roles: ['isDriver', 'isAssistant'],
  },
  {
    path: PATH.EMPLOYEES,
    iconStyle: 'iconUsersWhite',
    title: 'Employees',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CARS,
    iconStyle: 'iconTruckWhite',
    title: 'Cars',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PROFILE,
    iconStyle: 'iconProfileWhite',
    title: 'You account',
    roles: ['isAdmin', 'isOwner', 'isDriver', 'isAssistant'],
  },
] as const

function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const roles = getRole()
  const { currentCompany } = useSelector(companySelector)

  const [isVisibleMobileMenu, setVisibleMobileMenu] = useState(false)
  const item = localStorage.getItem('userInfo')
  const userInfo = item ? JSON.parse(item) : {}
  const handleLogout = () => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('init')
    dispatch(logout())
    window.location.href = PATH.AUTH
  }
  const location = useLocation()

  let retrievedObject
  const itemInit = localStorage.getItem('init')
  if (itemInit) {
    retrievedObject = JSON.parse(itemInit)
  }

  useEffect(() => {
    dispatch(setSelectedDrivers([]))
    dispatch(setSelectedTrucks([]))
  }, [currentCompany])

  return (
    <div>
      {/* DESKTOP */}
      <div
        className={styles.headerAccount}
        style={{ left: isVisibleMobileMenu ? 0 : 'auto' }}
      >
        <div className={styles.avatar}>
          <img
            src={userInfo?.avatar?.sm || 'img/users/no-avatar.svg'}
            className={styles.avatarImg}
          />

          <Link
            className={styles.avatarLink}
            to={PATH.PROFILE}
            onClick={() => setVisibleMobileMenu(false)}
          >
            {userInfo?.name}
          </Link>
          <a onClick={handleLogout} className={styles.iconLogout}></a>
        </div>

        {/* MOBILE */}

        <div className={styles.accountMobilePanel}>
          <a href="" className={styles.logoMobile}>
            <img
              className={styles.avatarImgMobile}
              src={retrievedObject?.logoWhite?.original}
              alt=""
            />
          </a>
          <div
            className={styles.menuMobile}
            onClick={() => setVisibleMobileMenu(!isVisibleMobileMenu)}
          />
        </div>

        <div className={styles.accountMobileMenu}>
          {LINKS.filter((link) => link.roles.some((role) => roles[role])).map(
            ({ title, path, iconStyle }) => (
              <NavLink
                key={path}
                className={classNames(
                  styles.navLink,
                  location.pathname === path && styles.active
                )}
                to={path}
                onClick={() => setVisibleMobileMenu(false)}
              >
                <i className={`${styles.icon} ${styles[iconStyle]}`} />{' '}
                <span>{title}</span>
              </NavLink>
            )
          )}
        </div>

        <div className="top-menu-account"></div>
      </div>
    </div>
  )
}

export default Header
