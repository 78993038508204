import classNames from 'classnames'
import React from 'react'

import styles from './Info404.module.css'

function Info404() {
  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <h2 className={classNames(styles.h2)}>404</h2>
          <p className={classNames(styles.fontBig, styles.colorGray)}>
            Page Not Found
          </p>
          <p className={classNames(styles.message)}>
            The page you are looking for might have been moved, renamed, or is
            temporarily unavailable. Maybe it took a wrong turn?
          </p>
        </div>
      </div>
    </div>
  )
}

export default Info404
