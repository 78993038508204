import HeaderMain from '../../components/header-main/HeaderMain'
import ReportsCalendar from './reports-calendar/ReportsCalendar'
import ReportsResult from './reports-result/ReportsResult'

import styles from './MyReports.module.css'
import classNames from 'classnames'
import { useEffect } from 'react'
import { fetchCars } from '../../redux/reducers/cars'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'

function MyReports() {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchCars())
  }, [])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div className="row">
            <ReportsCalendar />
            <div
              className={classNames(
                'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8',
                styles.accountRightCol
              )}
            >
              <ReportsResult />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyReports
