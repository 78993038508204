import classNames from 'classnames'
import styles from './ReportsResult.module.css'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { AppDispatch } from '../../../redux/store'
import {
  setCurrentReport,
  setIsVisibleDeleteModalReport,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import { getRole } from '../../../utils/getRole'

const TITLE_NAMES = ['Date', 'Truck', 'Route', 'Time', 'Expenses']

const BUTTON_INFO = [
  {
    color: 'bgRed',
    title: 'Total expenses',
    count: '',
  },
  {
    color: 'bgBlue',
    title: 'Total Route',
    count: '',
  },
]

const { isAssistant } = getRole()
function ReportsResult() {
  const dispatch = useDispatch<AppDispatch>()
  const [buttonInfo, setButtonInfo] = useState(BUTTON_INFO)
  const { reports } = useSelector(reportsSelector)

  const [sortField, setSortField] = useState<number | string | null>(null)
  const [sortOrder, setSortOrder] = useState('asc')

  const [sortNewReports, setSortNewReports] = useState<any>([])
  const sortReports = (field: any) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)

    const sortedReports = [...reports].sort((a, b) => {
      let valueA, valueB

      switch (field) {
        case 'Date':
          valueA = new Date(a.date_from)
          valueB = new Date(b.date_from)
          break
        case 'Truck':
          valueA = a.car.name
          valueB = b.car.name
          break
        case 'Route':
          valueA = a.distance
          valueB = b.distance
          break
        case 'Time':
          //@ts-ignore
          valueA = new Date(a.date_to) - new Date(a.date_from)
          //@ts-ignore

          valueB = new Date(b.date_to) - new Date(b.date_from)
          break
        case 'Expenses':
          valueA = a.cost
          valueB = b.cost
          break
        default:
          return 0
      }

      if (valueA < valueB) {
        return order === 'asc' ? -1 : 1
      }
      if (valueA > valueB) {
        return order === 'asc' ? 1 : -1
      }
      return 0
    })

    setSortNewReports(sortedReports)
  }

  useEffect(() => {
    setSortNewReports(reports)
  }, [reports])

  useEffect(() => {
    const sumDistance = reports?.reduce(
      (acc, report) => acc + (Number(report?.distance) || 0),
      0
    )
    const sumCost = reports?.reduce(
      (acc, report) => acc + Number(report?.cost || 0),
      0
    )

    setButtonInfo(
      buttonInfo?.map((info) => {
        if (info.title === 'Total expenses') {
          return {
            ...info,
            count: isAssistant ? '-' : `${sumCost.toLocaleString('en')} €`,
          }
        } else if (info.title === 'Total Route') {
          return {
            ...info,
            count: isAssistant ? '-' : `${sumDistance.toLocaleString('en')} km`,
          }
        }
        return info
      })
    )
  }, [reports])

  const handleEditReport = (item: any) => {
    dispatch(setCurrentReport(item))
    dispatch(setIsVisibleModalReport(true))
  }

  const handleDeleteReport = (item: any) => {
    dispatch(setCurrentReport(item))
    dispatch(setIsVisibleDeleteModalReport(true))
  }

  return (
    <>
      <div className={classNames('row', styles.totalAmountPanel)}>
        {buttonInfo.map(({ color, title, count }) => (
          <div key={title} className="col-6 col-sm-6 col-md-6 col-lg-6">
            <div className={classNames(styles.colCont, styles[color])}>
              <p className={styles.small2}>{title}</p>
              <b className={styles.fontBig}>{count}</b>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-3 mb-3" style={{ textAlign: 'center' }}>
        {!sortNewReports.length && (
          <div
            style={{ textAlign: 'center' }}
            className={classNames(styles.fontBig, styles.colorGray, 'mb-5')}
          >
            You have not yet sent a report for the selected period
          </div>
        )}
        <div
          onClick={() => {
            dispatch(setIsVisibleModalReport(true))
          }}
          className={styles.btn}
        >
          <i className={classNames(styles.icon, styles.iconReportsWhite)}></i>{' '}
          Send daily report
        </div>
      </div>
      {!!sortNewReports.length && (
        <div className={styles.tableBigWrapper}>
          <table
            className={classNames(styles.tableBig, styles.small2)}
            cellPadding="0"
            cellSpacing="0"
          >
            <tr className={styles.thRow}>
              {TITLE_NAMES.map((item) => (
                <th key={item} onClick={() => sortReports(item)}>
                  <div className={styles.tableSortCol}>{item}</div>
                </th>
              ))}
            </tr>

            <tbody>
              {sortNewReports?.map((item: any) => {
                const getDate = (isoDateString: string) => {
                  const date = new Date(isoDateString)

                  const day = date.getDate().toString().padStart(2, '0') // Получаем день
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, '0') // Получаем месяц
                  const year = date.getFullYear() // Получаем год

                  const formattedDate = `${day}.${month}.${year}`
                  return formattedDate // Выведет: 15.02.2024
                }

                const getTime = (date: string) => {
                  const newDate = new Date(date)
                  const hours = newDate.getHours().toString().padStart(2, '0') // Получаем часы
                  const minutes = newDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')

                  return `${hours}:${minutes}`
                }

                const date1 = new Date(item.date_from)
                const date2 = new Date(item.date_to)

                //@ts-ignore
                const differenceInMilliseconds = date2 - date1
                const differenceInHours = Math.floor(
                  differenceInMilliseconds / (1000 * 60 * 60)
                )
                const differenceInMinutes = Math.round(
                  (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
                )

                return (
                  <tr className={styles.bxShadow}>
                    <td className={styles.tdDate}>
                      {getDate(item.date_from)}
                      <div className={styles.small2}>
                        {getTime(item.date_from)}&ndash; {getTime(item.date_to)}
                      </div>
                    </td>
                    <td className={styles.tdTruck}>
                      <b>{item?.car?.name}</b>
                    </td>
                    <td className={styles.tdKm}>
                      {item.distance.toLocaleString('en')} km
                    </td>
                    <td className={styles.tdH}>
                      {differenceInHours} h {differenceInMinutes} m
                    </td>
                    <td className={styles.tdMoney}>
                      {item.cost.toLocaleString('en')} €
                    </td>
                    <td
                      className={styles.tdButtons}
                      style={{ textAlign: 'right' }}
                    >
                      {item?.editable && (
                        <a
                          onClick={() => handleEditReport(item)}
                          className={classNames(
                            styles.btn,
                            styles.btnSmall,
                            styles.btnIcon,
                            styles.btnBlueOutline
                          )}
                        >
                          <i
                            className={classNames(styles.icon, styles.iconEdit)}
                          />
                        </a>
                      )}
                      <div
                        onClick={() => handleDeleteReport(item)}
                        className={classNames(
                          styles.btn,
                          styles.btnSmall,
                          styles.btnIcon,
                          styles.btnOutline
                        )}
                      >
                        <i
                          className={classNames(
                            styles.icon,
                            styles.iconDeleteRed
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ReportsResult
