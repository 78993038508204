export const PATH = {
  DASHBOARD: '/',
  AUTH: '/auth',
  CARS: '/cars',
  COMPANIES: '/companies',
  REPORTS: '/reports',
  MY_REPORTS: '/my-reports',
  EMPLOYEES: '/employees',
  PROFILE: '/profile',
  RESET_PASSWORD: '/pass_recovery',
} as const
