import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import styles from './AddCompanyModal.module.css'
import classNames from 'classnames'
import {
  fetchCreateCompany,
  setIsVisibleModalCompany,
} from '../../../redux/reducers/company'
import { useEffect, useState } from 'react'
//@ts-ignore
import ModalImage from 'react-modal-image'
import { companySelector } from '../../../redux/selectors/company-selector'
import { getRole } from '../../../utils/getRole'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'

const { isOwner } = getRole()
function AddCompanyModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentCompanyForEdit } = useSelector(companySelector)

  const [name, setName] = useState('')
  const [domain, setDomain] = useState('')
  const [isActive, setIsActive] = useState(false)

  const [logoDark, setLogoDark] = useState<any>(null)
  const [logoDarkSelected, setLogoDarkSelected] = useState<any>(null)

  const [logoWhite, setLogoWhite] = useState<any>(null)
  const [logoWhiteSelected, setLogoWhiteSelected] = useState<any>(null)

  const [backgroundImage, setBackgroundImage] = useState<any>(null)
  const [backgroundImageSelected, setBackgroundImageSelected] =
    useState<any>(null)

  const [isDisabledButton, setIsDisabledButton] = useState(true)

  const handleImageLogoDarkChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setLogoDarkSelected(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setLogoDark(imageUrl)
    }
  }

  const handleImageLogoWhiteChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setLogoWhiteSelected(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setLogoWhite(imageUrl)
    }
  }

  const handleImageBackgroundChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setBackgroundImageSelected(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setBackgroundImage(imageUrl)
    }
  }
  useEffect(() => {
    if (currentCompanyForEdit) {
      setName(currentCompanyForEdit?.name)
      setDomain(currentCompanyForEdit?.domain)
      setIsActive(currentCompanyForEdit?.active)
      setLogoDark(currentCompanyForEdit?.logoDark.original)
      setLogoWhite(currentCompanyForEdit?.logoWhite.original)
      setBackgroundImage(currentCompanyForEdit?.background)
    }
  }, [])

  const handleSaveCompany = () => {
    dispatch(
      fetchCreateCompany({
        name,
        domain,
        active: isActive,
        logoDark: logoDarkSelected,
        logoWhite: logoWhiteSelected,
        background: backgroundImageSelected,
      })
    )
  }

  useEffect(() => {
    const isNameChanged = name !== currentCompanyForEdit?.name
    const isDomainChanged = domain !== currentCompanyForEdit?.domain
    const isStatusChanged = isActive !== currentCompanyForEdit?.active
    const isNewPhoto =
      !!logoDarkSelected || !!logoWhiteSelected || !!backgroundImageSelected

    const shouldDisableButton = !(
      (isNameChanged || isDomainChanged || isStatusChanged || isNewPhoto) &&
      name &&
      domain
    )

    setIsDisabledButton(shouldDisableButton)
  }, [
    name,
    domain,
    currentCompanyForEdit,
    isActive,
    logoDarkSelected,
    logoWhiteSelected,
    backgroundImageSelected,
  ])

  useClearBodyNoScrollClass()

  return (
    <div
      style={{ display: 'block' }}
      className={styles.win}
      id="win_company_edit"
    >
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalCompany(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3 style={{ textAlign: 'center' }} className="mb-5">
              {currentCompanyForEdit ? 'Edit company' : 'Add company'}
            </h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 mb-3">
                  <small className="d-block mb-1">Company name*</small>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    type="text"
                    name="name"
                    placeholder="Company name"
                  />
                </div>

                <div className="col-12 mb-3">
                  <small className="d-block mb-1">Domain*</small>
                  <input
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    required
                    type="text"
                    name="domain"
                    placeholder="Panel URL"
                    disabled={isOwner}
                  />
                </div>

                <div className="col-6 mb-3">
                  <small className="d-block mb-1">Logo dark</small>
                  <div className={styles.loadPhoto}>
                    <div
                      className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                    >
                      <ModalImage
                        className={`${styles.carImgBg} ${styles.companyImgBg} mb-1`}
                        small={logoDark || 'img/cars/no-car-photo.svg'}
                        medium={logoDark || 'img/cars/no-car-photo.svg'}
                        alt="User Photo"
                      />
                      <div
                        style={{ textAlign: 'left' }}
                        className={styles.smallUpload}
                      >
                        <i
                          className={classNames(
                            'mr-1',
                            'd-sm-inline-block',
                            styles.icon,
                            styles.iconDownload,
                            styles.iconUpload,
                            styles.icon16
                          )}
                        ></i>{' '}
                        Upload
                      </div>
                      <input
                        type="file"
                        name="logo_dark"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleImageLogoDarkChange(event)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 mb-3">
                  <small className="d-block mb-1">Logo white</small>
                  <div className={styles.loadPhoto}>
                    <div
                      className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                    >
                      <ModalImage
                        className={`${styles.carImgBg} ${styles.companyImgBg} mb-1`}
                        small={logoWhite || 'img/cars/no-car-photo.svg'}
                        medium={logoWhite || 'img/cars/no-car-photo.svg'}
                        alt="User Photo"
                      />
                      <div
                        style={{ textAlign: 'left' }}
                        className={styles.smallUpload}
                      >
                        <i
                          className={classNames(
                            'mr-1',
                            'd-sm-inline-block',
                            styles.icon,
                            styles.iconDownload,
                            styles.iconUpload,
                            styles.icon16
                          )}
                        ></i>{' '}
                        Upload
                      </div>
                      <input
                        style={{ textAlign: 'left' }}
                        className={styles.inputUpload}
                        type="file"
                        name="logo_white"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleImageLogoWhiteChange(event)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <small className="d-block mb-1">Background image</small>
                  <div className={styles.loadPhoto}>
                    <div
                      className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                    >
                      <ModalImage
                        className={`${styles.carImgBg} ${styles.companyImgBg} mb-1`}
                        small={backgroundImage || 'img/cars/no-car-photo.svg'}
                        alt="User Photo"
                      />
                      <div
                        style={{ textAlign: 'left' }}
                        className={styles.smallUpload}
                      >
                        <i
                          className={classNames(
                            'mr-1',
                            styles.icon,
                            styles.iconDownload,
                            styles.iconUpload,
                            styles.icon16
                          )}
                        ></i>{' '}
                        Upload
                      </div>
                      <input
                        type="file"
                        name="logo_white"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleImageBackgroundChange(event)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3 mb-3">
                  <div className="small">
                    <input
                      value={isActive ? '1' : '0'}
                      onChange={(e) => setIsActive(e.target.checked)}
                      type="checkbox"
                      id="company_enable"
                      name="enable"
                      checked={isActive}
                    />
                    <label htmlFor="company_enable">Active</label>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={handleSaveCompany}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                    disabled={isDisabledButton}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    {currentCompanyForEdit ? 'Save' : 'Send'}
                  </button>
                </div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalCompany(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCompanyModal
