import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'

export const DEFINEDS = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastYears: startOfYear(addYears(new Date(), -1)),
  endOfLastYears: endOfYear(addYears(new Date(), -1)),
}

export const staticRangesArray = [
  {
    label: 'Today',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfToday,
      endDate: DEFINEDS.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfYesterday,
      endDate: DEFINEDS.endOfYesterday,
    }),
  },
  {
    label: 'Last Week',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfLastWeek,
      endDate: DEFINEDS.endOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfLastMonth,
      endDate: DEFINEDS.endOfLastMonth,
    }),
  },
  {
    label: 'Last Years',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfLastYears,
      endDate: DEFINEDS.endOfLastYears,
    }),
  },
]
