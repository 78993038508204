import { ComponentType, FunctionComponent } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { getRole } from './utils/getRole'

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType<any>
  allowedRoles: string[]
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  ...rest
}) => {
  const { hasRole, ...roles } = getRole()

  // Проверка наличия роли
  const roleCheck = allowedRoles.some(
    (role) => roles[role as keyof typeof roles]
  )

  return (
    <Route
      {...rest}
      render={(props) =>
        hasRole && roleCheck ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/error' }} />
        )
      }
    />
  )
}

export default ProtectedRoute
