import classNames from 'classnames'
import styles from './AddUserModal.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchAddUserForAdmin,
  fetchEditUser,
  setIsVisibleModalUser,
} from '../../../redux/reducers/users'
import { usersSelector } from '../../../redux/selectors/users-selector'
import { companySelector } from '../../../redux/selectors/company-selector'
import { getRole } from '../../../utils/getRole'
//@ts-ignore
import ModalImage from 'react-modal-image'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'

const { isAdmin, isOwner } = getRole()

function AddUserModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentUser } = useSelector(usersSelector)
  const { company, currentCompany } = useSelector(companySelector)

  const [login, setLogin] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [companyBySelect, setCompanyBySelect] = useState<string | number>()
  const [role, setRole] = useState('')
  const [isActive, setIsActive] = useState(currentUser?.active)

  const [photo, setPhoto] = useState<null | string>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)

  const [isDisabledButton, setIsDisabledButton] = useState(true)

  useEffect(() => {
    if (currentUser) {
      const {
        name,
        active,
        email,
        role,
        avatar: { sm },
        login = '',
      } = currentUser
      setLogin(login)
      setName(name)
      setEmail(email)
      setRole(role)
      setPhoto(sm)
      setIsActive(active)
      setCompanyBySelect(currentCompany?.id)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      const { name, active, login = '' } = currentUser
      setName(name)
      setIsActive(active)
      setLogin(login)
    }
  }, [])

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    setSelectedImage(file)
    const imageUrl = file ? URL.createObjectURL(file) : ''
    setPhoto(imageUrl)
  }

  const handleSaveUser = () => {
    const commonPayload = {
      name,
      selectedImage,
      isActive,
      email,
      role,
      password,
      confirmPassword,
      login,
    }

    const adminPayload = {
      ...commonPayload,
      id: currentUser?.id,
      currentCompanyId: companyBySelect || currentCompany?.id,
    }

    if (isAdmin) {
      dispatch(
        currentUser
          ? fetchEditUser(adminPayload)
          : fetchAddUserForAdmin(adminPayload)
      )
    } else if (name || selectedImage) {
      dispatch(fetchEditUser({ ...commonPayload, id: currentUser?.id }))
    }
  }

  useEffect(() => {
    const isNewUser = !currentUser?.id
    const isValidNewUser =
      login &&
      role &&
      role !== 'Select a role' &&
      name &&
      password &&
      confirmPassword === password
    const isValidPassword = confirmPassword === password && password !== ''

    const isActiveWasChanged = isActive === currentUser?.active

    const isUnchangedExistingUser =
      companyBySelect === currentCompany?.id &&
      (!name || name === currentUser?.name) &&
      email === currentUser?.email &&
      (!login || login === currentUser?.login) &&
      role === currentUser?.role &&
      !isValidPassword &&
      isActiveWasChanged
    const areRequiredFieldsFilled =
      name !== '' && login !== '' && confirmPassword === password

    setIsDisabledButton(
      (isNewUser ? !isValidNewUser : isUnchangedExistingUser) ||
        !areRequiredFieldsFilled
    )
  }, [
    confirmPassword,
    currentUser,
    email,
    name,
    password,
    role,
    login,
    companyBySelect,
    currentCompany,
    isActive,
  ])

  const getValueSelect = () => {
    if (companyBySelect) {
      return companyBySelect
    }
    return currentCompany?.id || ''
  }

  useClearBodyNoScrollClass()

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalUser(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`mb-5 ${styles.h3}}`)}
            >
              {currentUser ? 'Edit employee' : 'Add employee'}
            </h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className={styles.loadPhoto}>
                    <div
                      className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                    >
                      <ModalImage
                        className={`${styles.profileAvatar}`}
                        small={photo || 'img/users/no-avatar.svg'}
                        alt="User Photo"
                      />
                      <div>
                        <span className={styles.small}>Set photo</span>
                        <input
                          type="file"
                          name="avatar"
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">Company</small>

                  <select
                    value={getValueSelect()}
                    onChange={(e) => {
                      setCompanyBySelect(e.target.value)
                    }}
                    className="mb-2"
                    required
                    disabled={!isAdmin}
                  >
                    {isAdmin &&
                      company.map((item) => {
                        const { id, name } = item
                        return <option value={id}>{name}</option>
                      })}
                    {isOwner && (
                      <option value={currentCompany?.id}>
                        {currentCompany?.name}
                      </option>
                    )}
                  </select>
                  <div className={styles.small}>
                    <input
                      id="user_enable"
                      checked={isActive}
                      type="checkbox"
                      onChange={(e) => setIsActive(e.target.checked)}
                      name="user_enable"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="user_enable">
                      {' '}
                      Active
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">Status</small>
                  <select
                    value={role ? role : ''}
                    onChange={(e) => setRole(e.target.value)}
                    name="status"
                    required
                  >
                    <option selected disabled={!!currentUser?.id}>
                      Select a role
                    </option>
                    <option selected value="driver">
                      Driver
                    </option>
                    <option value="assistant">Assistant</option>
                    <option value="owner">Owner</option>
                  </select>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">Login*</small>
                  <input
                    onChange={(e) => {
                      setLogin(e.target.value)
                    }}
                    value={login}
                    required
                    type="text"
                    name="login"
                    placeholder="Enter your login"
                  />
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">E-mail</small>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    value={email}
                    type="email"
                    name="email"
                    placeholder="Enter your E-mail"
                    className="mb-1"
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <small className="d-block mb-1">Name*</small>
                  <input
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    value={name}
                    required
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                  />
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">Password</small>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    value={password}
                    autoComplete="off"
                    readOnly
                    onFocus={(e) => e.target.removeAttribute('readOnly')}
                    className="mb-1"
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <small className="d-block mb-1">Confirm password</small>
                  <input
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                    value={confirmPassword}
                    type="password"
                    autoComplete="off"
                    name="password_confirm"
                    placeholder="Confirm password"
                    readOnly
                    onFocus={(e) => e.target.removeAttribute('readOnly')}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={handleSaveUser}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                    disabled={isDisabledButton}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />
                    Save
                  </button>
                </div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalUser(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUserModal
