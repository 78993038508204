import axios from 'axios'
import { getHost } from './getHost'
import { getCookie } from './getCookies'

const axiosInstance = axios.create({
  baseURL: getHost(),
})

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('auth')
    if (accessToken) {
      if (config.headers) {
        config.headers.accept = 'application/json'
        config.headers['X-CSRF-TOKEN'] = ''
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    }

    if (!(config.data instanceof FormData)) {
      config.headers['Content-Type'] = 'application/json'
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
