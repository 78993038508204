import classNames from 'classnames'
import styles from './AddCarModal.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchAddCarForAdmin,
  fetchEditCar,
  setIsVisibleModalCar,
} from '../../../redux/reducers/cars'
import { carsSelector } from '../../../redux/selectors/cars-selector'

//@ts-ignore
import ModalImage from 'react-modal-image'
import { getRole } from '../../../utils/getRole'
import { companySelector } from '../../../redux/selectors/company-selector'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'

function AddCarModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentCar } = useSelector(carsSelector)
  const { currentCompany } = useSelector(companySelector)

  const [carName, setCarName] = useState('')
  const [isActive, setIsActive] = useState(false)

  const [photo, setPhoto] = useState<null | string>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [isDisabledButton, setIsDisabledButton] = useState(true)

  useEffect(() => {
    if (currentCar) {
      const { name, active } = currentCar
      setCarName(name)
      setIsActive(active)
      setPhoto(currentCar.image.md)
    }
  }, [])

  useEffect(() => {
    const isCarNameChanged = carName && carName !== currentCar?.name
    const isStatusChanged = isActive !== currentCar?.active && carName
    const shouldDisableButton = !(currentCar
      ? isCarNameChanged || isStatusChanged
      : carName)

    setIsDisabledButton(shouldDisableButton)
  }, [carName, currentCar, isActive])

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setSelectedImage(file)
      const imageUrl = file ? URL.createObjectURL(file) : ''
      setPhoto(imageUrl)
    }
  }

  const onChangeCarName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCarName(e.target.value)
  }

  const { isAdmin } = getRole()

  const handleSaveCar = () => {
    const commonParams = { carName, selectedImage, isActive }

    const action = currentCar
      ? fetchEditCar({ ...commonParams, id: currentCar.id })
      : isAdmin
        ? fetchAddCarForAdmin({
            ...commonParams,
            currentCompanyId: currentCompany?.id,
          })
        : fetchEditCar(commonParams)

    if (isAdmin || carName) {
      dispatch(action)
    }
  }

  useClearBodyNoScrollClass()

  return (
    <div>
      <div style={{ display: 'block' }} className={styles.win}>
        <div className={styles.winContainer}>
          <div className={styles.winContent}>
            <div
              onClick={() => dispatch(setIsVisibleModalCar(false))}
              className={styles.close}
            >
              ×
            </div>
            <div className={styles.winContentFulltext}>
              <h3
                style={{ textAlign: 'center' }}
                className={classNames(`mb-5 ${styles.h3}}`)}
              >
                {currentCar ? 'Edit car' : 'Add car'}
              </h3>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-12 mb-3">
                    <small className="d-block mb-1">Title</small>
                    <input
                      onChange={(e) => onChangeCarName(e)}
                      value={carName}
                      required
                      type="text"
                      name="name"
                      placeholder="Simple car title"
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <div className={styles.loadPhoto}>
                      <div
                        className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                      >
                        <ModalImage
                          className={`${styles.carImgBg} mb-1`}
                          small={photo || 'img/cars/no-car-photo.svg'}
                          alt="User Photo"
                        />
                        <div
                          style={{ textAlign: 'left' }}
                          className={styles.small}
                        >
                          <i
                            className={classNames(
                              'mr-1',
                              'd-sm-inline-block',
                              styles.icon,
                              styles.iconDownload,
                              styles.iconUpload,
                              styles.icon16
                            )}
                          />{' '}
                          Upload
                        </div>
                        <input
                          type="file"
                          name="photo"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleImageChange(event)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3 mb-3">
                    <div className={styles.small}>
                      <input
                        id="car_enable"
                        checked={isActive}
                        type="checkbox"
                        onChange={(e) => {
                          setIsActive(e.target.checked)
                        }}
                        name="car_enable"
                        className={styles.input}
                      />
                      <label className={styles.label} htmlFor="car_enable">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-12 col-sm-6 mt-1 mb-1"
                    style={{ textAlign: 'left' }}
                  >
                    <button
                      onClick={handleSaveCar}
                      className={classNames(
                        'd-block',
                        'd-sm-inline-block',
                        styles.btn
                      )}
                      disabled={isDisabledButton}
                    >
                      <i
                        className={`${styles.icon} ${styles.iconCheckWhite}`}
                      />{' '}
                      {currentCar ? 'Save' : 'Send'}
                    </button>
                  </div>
                  <div
                    className="col-12 col-sm-6 mt-1 mb-1"
                    style={{ textAlign: 'right' }}
                  >
                    <div
                      onClick={() => dispatch(setIsVisibleModalCar(false))}
                      className={classNames(
                        'd-block',
                        'd-sm-inline-block',
                        styles.btn,
                        styles.btnSmall,
                        styles.btnBlueOutline
                      )}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCarModal
