import classNames from 'classnames'
import styles from './ReportsResult.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { AppDispatch } from '../../../redux/store'
import {
  fetchReportFile,
  setCurrentReport,
  setIsVisibleDeleteModalReport,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import Spinner from '../../../components/spinner/Spinner'

const TITLE_NAMES = ['Date', 'Employees', 'Truck', 'Route', 'Time', 'Expenses']

const BUTTON_INFO = [
  {
    color: 'bgRed',
    title: 'Total expenses',
    count: '',
  },
  {
    color: 'bgBlue',
    title: 'Total Route',
    count: '',
  },
  {
    color: 'bgLightBlue',
    title: 'Cars used',
    count: '',
  },
  {
    color: 'bgTurquoise',
    title: 'Employees',
    count: '',
  },
]

function ReportsResult({ dateFrom, dateTo, getEmployees, getCars }: any) {
  const dispatch = useDispatch<AppDispatch>()
  const [sortField, setSortField] = useState<number | string | null>(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [buttonInfo, setButtonInfo] = useState(BUTTON_INFO)
  const { reports, isPendingGetReports } = useSelector(reportsSelector)
  const [sortNewReports, setSortNewReports] = useState<any>([])

  const sortReports = (field: number | string) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)

    if (field === 'Truck') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.car.name < b.car.name) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.car.name > b.car.name) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Employees') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.user.name < b.user.name) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.user.name > b.user.name) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Date') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.date_from < b.date_from) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.date_from > b.date_from) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Route') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.distance < b.distance) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.distance > b.distance) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Expenses') {
      const sortedReports = [...reports].sort((a, b) => {
        //@ts-ignore
        if (a.cost < b.cost) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (a.cost > b.cost) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }

    if (field === 'Time') {
      const sortedReports = [...reports].sort((a, b) => {
        const dateAFrom = new Date(a.date_from)
        const dateATo = new Date(a.date_to)

        const dateBFrom = new Date(b.date_from)
        const dateBTo = new Date(b.date_to)

        //@ts-ignore
        const differenceInMillisecondsA = dateATo - dateAFrom
        //@ts-ignore
        const differenceInMillisecondsB = dateBTo - dateBFrom

        //@ts-ignore
        if (differenceInMillisecondsA < differenceInMillisecondsB) {
          return order === 'asc' ? -1 : 1
        }
        //@ts-ignore

        if (differenceInMillisecondsA > differenceInMillisecondsB) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortNewReports(sortedReports)
    }
  }

  useEffect(() => {
    setSortNewReports(reports)
  }, [reports])

  useEffect(() => {
    const sumDistance = reports?.reduce(
      (acc, report) => acc + (Number(report?.distance) || 0),
      0
    )
    const sumCost = reports?.reduce(
      (acc, report) => acc + Number(report?.cost || 0),
      0
    )

    const filteredCars = reports
      .map((item) => item.car)
      .filter((car) => car !== null)
    //@ts-ignore
    const uniqueCarIds = [...new Set(filteredCars.map((car) => car.id))]

    const filteredUsers = reports
      .map((item) => item.user)
      .filter((user) => user !== null)
    //@ts-ignore
    const uniqueUsersIds = [...new Set(filteredUsers.map((user) => user.id))]

    setButtonInfo(
      buttonInfo.map((info) => {
        if (info.title === 'Total expenses') {
          return { ...info, count: `${sumCost.toLocaleString('en')} €` }
        } else if (info.title === 'Total Route') {
          return { ...info, count: `${sumDistance.toLocaleString('en')} km` }
        } else if (info.title === 'Cars used') {
          return { ...info, count: `${uniqueCarIds.length}` }
        } else if (info.title === 'Employees') {
          return { ...info, count: `${uniqueUsersIds.length}` }
        }
        return info
      })
    )
  }, [reports])

  const handleEditReport = (item: any) => {
    dispatch(setCurrentReport(item))
    dispatch(setIsVisibleModalReport(true))
  }

  const handleDeleteReport = (item: any) => {
    dispatch(setCurrentReport(item))
    dispatch(setIsVisibleDeleteModalReport(true))
  }

  const [currentPage, setCurrentPage] = useState(1)
  const reportsPerPage = 50

  const pageCount = Math.ceil(sortNewReports.length / reportsPerPage)

  const indexOfLastReport = currentPage * reportsPerPage
  const indexOfFirstReport = indexOfLastReport - reportsPerPage
  const currentReports = sortNewReports.slice(
    indexOfFirstReport,
    indexOfLastReport
  )

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const selectedLoanWithoutAccount = false

  const shouldRenderWarningInfo = 'dasd'

  return (
    <>
      {' '}
      {isPendingGetReports ? (
        <Spinner />
      ) : (
        <>
          {' '}
          <div className="row align-items-center mb-3">
            <div className="col-5 col-sm-6">
              <h4 className={classNames(styles.h4, 'mb-0')}>
                Results ({sortNewReports.length})
              </h4>
            </div>
            <div className="col-7 col-sm-6" style={{ textAlign: 'right' }}>
              <span
                onClick={() =>
                  dispatch(
                    fetchReportFile({
                      dateFrom: new Date(dateFrom).toISOString().split('T')[0],
                      dateTo: new Date(dateTo).toISOString().split('T')[0],
                      exportFormat: 'pdf',
                      getEmployees,
                      getCars,
                    })
                  )
                }
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnTrans
                )}
              >
                <i className={classNames(styles.icon, styles.iconDownload)} />
                PDF
              </span>
              <a
                onClick={() =>
                  dispatch(
                    fetchReportFile({
                      dateFrom: new Date(dateFrom).toISOString().split('T')[0],
                      dateTo: new Date(dateTo).toISOString().split('T')[0],
                      exportFormat: 'xlsx',
                      getEmployees,
                      getCars,
                    })
                  )
                }
                download
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnTrans
                )}
              >
                <i className={classNames(styles.icon, styles.iconDownload)} />
                XLS
              </a>
            </div>
          </div>
          <div className={classNames('row', styles.totalAmountPanel)}>
            {buttonInfo.map(({ color, title, count }) => (
              <div key={title} className="col-6 col-sm-3 col-md-3 col-lg-3">
                <div className={classNames(styles.colCont, styles[color])}>
                  <p className={styles.small2}>{title}</p>
                  <b className={styles.fontBig}>{count}</b>
                </div>
              </div>
            ))}
          </div>
          {!!sortNewReports.length ? (
            <div className={styles.tableBigWrapper}>
              <table
                className={classNames(styles.tableBig, styles.small2)}
                cellPadding="0"
                cellSpacing="0"
              >
                <tr className={styles.thRow}>
                  {TITLE_NAMES.map((item) => (
                    <th key={item} onClick={() => sortReports(item)}>
                      <div className={styles.tableSortCol}>{item}</div>
                    </th>
                  ))}
                </tr>
                <tbody>
                  {currentReports.map((item: any) => {
                    const getDate = (isoDateString: string) => {
                      const date = new Date(isoDateString)

                      const day = date.getDate().toString().padStart(2, '0')
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, '0')
                      const year = date.getFullYear()

                      const formattedDate = `${day}.${month}.${year}`
                      return formattedDate
                    }

                    const getTime = (date: string) => {
                      const newDate = new Date(date)
                      const hours = newDate
                        .getHours()
                        .toString()
                        .padStart(2, '0')
                      const minutes = newDate
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')

                      return `${hours}:${minutes}`
                    }

                    const date1 = new Date(item.date_from)
                    const date2 = new Date(item.date_to)

                    //@ts-ignore
                    const differenceInMilliseconds = date2 - date1
                    const differenceInHours = Math.floor(
                      differenceInMilliseconds / (1000 * 60 * 60)
                    )
                    const differenceInMinutes = Math.round(
                      (differenceInMilliseconds % (1000 * 60 * 60)) /
                        (1000 * 60)
                    )
                    return (
                      <tr className={styles.bxShadow}>
                        <td className={styles.tdDate}>
                          {getDate(item.date_from)}
                          <div className={styles.small2}>
                            {getTime(item.date_from)}&ndash;{' '}
                            {getTime(item.date_to)}
                          </div>
                        </td>
                        <td className={styles.tdMain}>
                          <a
                            className={styles.avatarLink}
                            href="#"
                            onClick={() => handleEditReport(item)}
                          >
                            <span
                              style={{
                                backgroundImage: `url(${item?.user?.avatar.original || 'img/users/no-avatar.svg'})`,
                              }}
                              className={styles?.avatarImg}
                            ></span>
                            <span className={styles.name}>
                              {item?.user?.name}
                            </span>
                          </a>
                        </td>
                        <td className={styles.tdTruck}>{item?.car?.name}</td>
                        <td className={styles.tdKm}>
                          {item?.distance.toLocaleString('en')} km
                        </td>
                        <td className={styles.tdH}>
                          {differenceInHours} h {differenceInMinutes} m
                        </td>
                        <td className={styles.tdMoney}>
                          {item?.cost.toLocaleString('en')} €
                        </td>
                        <td
                          className={styles.tdButtons}
                          style={{ textAlign: 'right' }}
                        >
                          {item?.editable && (
                            <a
                              onClick={() => handleEditReport(item)}
                              className={classNames(
                                styles.btn,
                                styles.btnSmall,
                                styles.btnIcon,
                                styles.btnBlueOutline
                              )}
                            >
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconEdit
                                )}
                              />
                            </a>
                          )}
                          <div
                            onClick={() => handleDeleteReport(item)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall,
                              styles.btnIcon,
                              styles.btnOutline
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconDeleteRed
                              )}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{ textAlign: 'center' }}
              className={classNames(styles.fontBig, styles.colorGray, 'mb-5')}
            >
              {!selectedLoanWithoutAccount && shouldRenderWarningInfo && false}
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            {Array.from({ length: pageCount }, (_, index) => (
              <span
                className={classNames(
                  styles.pagingBtn,
                  styles.btn,
                  styles.btnSmall,
                  styles.btnBlueOutline,
                  currentPage === index + 1 && styles.activeButton
                )}
                key={index + 1}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </span>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ReportsResult
