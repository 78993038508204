import { createSlice } from '@reduxjs/toolkit'
import { Range } from 'react-date-range'
import { DEFINEDS } from '../../constants/date'

export interface InitState {
  calendarRange: Array<Range>
  selectedTrucks: { id: string; name: string }[]
  selectedDrivers: { id: string; name: string }[]
}

const initialState: InitState = {
  calendarRange: [
    {
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
      key: 'selection',
    },
  ],
  selectedTrucks: [],
  selectedDrivers: [],
}

export const reportFilterSlice = createSlice({
  name: 'reportFilter',
  initialState,
  reducers: {
    setCalendarRange: (state, action) => {
      return { ...state, calendarRange: action.payload }
    },
    setSelectedTrucks: (state, action) => {
      return { ...state, selectedTrucks: action.payload }
    },
    setSelectedDrivers: (state, action) => {
      return { ...state, selectedDrivers: action.payload }
    },
  },
})

export const { setCalendarRange, setSelectedTrucks, setSelectedDrivers } =
  reportFilterSlice.actions

export default reportFilterSlice.reducer
