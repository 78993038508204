import styles from './Footer.module.css'

function Footer() {
  return (
    <div className={`${styles.footer} ${styles.bgDarkBlue}`}>
      <div className={styles.container}>
        <div className={`${styles.copyright} ${styles.small2}`}>
          Copyright © Timex Srl.
          <br />
          All rights reserved.
        </div>
      </div>
      <a className={`${styles.linkTop} ${styles.linkto}`} href="#body">
        &lsaquo;<span>To top</span>
      </a>
    </div>
  )
}

export default Footer
