import React, { useEffect, useState } from 'react'

import styles from './Profile.module.css'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { fetchEditUser } from '../../redux/reducers/users'

//@ts-ignore
import ModalImage from 'react-modal-image'
import { setIsVisibleModalChangeEmail } from '../../redux/reducers/auth'

function Profile() {
  const item = localStorage.getItem('userInfo')
  const userInfo = item ? JSON.parse(item) : {}
  const dispatch = useDispatch<AppDispatch>()

  const [id, setId] = useState()
  const [login, setLogin] = useState('')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [photo, setPhoto] = useState<null | string>(null)
  const [bigPhoto, setBigPhoto] = useState<null | string>(null)
  const [isDisabledButton, setIsDisabledButton] = useState(true)

  const [selectedImage, setSelectedImage] = useState<any>(null)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    setSelectedImage(file)
    const imageUrl = file ? URL.createObjectURL(file) : ''
    setPhoto(imageUrl)
  }

  useEffect(() => {
    const isMust = (login && name) || (login && name && !email)
    const isLoginChanged = login && login !== userInfo.login
    const isNameChanged = name && name !== userInfo.name
    const isEmailChanged = email !== userInfo.email
    const isValidPassword = confirmPassword === password && password !== ''
    const isPhotoChanged = !!selectedImage;

    setIsDisabledButton(
      !(isLoginChanged || isNameChanged || isEmailChanged || isValidPassword || isPhotoChanged) ||
        !isMust
    )
  }, [
    confirmPassword,
    email,
    login,
    name,
    password,
    userInfo.email,
    userInfo.login,
    userInfo.name,
    selectedImage,
  ])

  useEffect(() => {
    if (userInfo) {
      const { name, email, id, role, avatar, login = '' } = userInfo
      setLogin(login)
      setName(name)
      setEmail(email)
      setId(id)
      setPhoto(avatar?.sm)
      setBigPhoto(avatar?.original)
    }
  }, [])

  const handleSaveUser = () => {
    dispatch(
      fetchEditUser({
        id,
        name,
        email,
        password,
        confirmPassword,
        selectedImage,
        isActive: true,
        login,
      })
    )
  }

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <h3 className={styles.h3} style={{ textAlign: 'center' }}>
            You account
          </h3>

          <div className="row">
            <div className="col-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-2 col-lg-8  offset-xl-3 col-xl-6">
              <div className={styles.blockWhite}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className={classNames('mb-3', styles.loadPhoto)}>
                    <div
                      className={`${styles.colorBlue} ${styles.loadPhotoInputWrapper}`}
                    >
                      <ModalImage
                        className={`${styles.loadedPhoto} ${styles.profileAvatar}`}
                        small={photo || 'img/users/no-avatar.svg'}
                        large={bigPhoto || 'img/users/no-avatar.svg'}
                        alt="User Photo"
                      />
                      <div>
                        <span className={styles.small}>Set photo</span>
                        <input
                          onChange={(e) => {
                            handleImageChange(e)
                          }}
                          type="file"
                          name="avatar"
                          id="avatar"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.formLine}>
                    <small>Login*</small>
                    <input
                      onChange={(e) => {
                        setLogin(e.target.value)
                      }}
                      value={login}
                      required
                      type="text"
                      name="login"
                      placeholder="Enter your login"
                    />
                  </div>

                  <div className={styles.formLine}>
                    <small>Name*</small>
                    <input
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                      value={name}
                      required
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                    />
                  </div>

                  <div className={styles.formLine}>
                    <small>E-mail</small>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      value={email}
                      type="email"
                      name="email"
                      placeholder="Enter your E-mail"
                      className="mb-1"
                    />
                    <div style={{ textAlign: 'right' }}>
                      <a
                        onClick={() =>
                          dispatch(setIsVisibleModalChangeEmail(true))
                        }
                      >
                        Change E-mail
                      </a>
                    </div>
                  </div>

                  <div className={styles.formLine}>
                    <small>New password</small>
                    <input
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      value={password}
                      className="mb-1"
                      type="password"
                      name="password"
                      placeholder="Enter to change the password"
                    />
                    <input
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }}
                      value={confirmPassword}
                      type="password"
                      name="password_confirm"
                      placeholder="Confirm new password"
                    />
                  </div>

                  <div className="row mt-2">
                    <div
                      className="col-12 col-sm-12 mt-1 mb-1"
                      style={{ textAlign: 'left' }}
                    >
                      <button
                        disabled={isDisabledButton}
                        type="button"
                        onClick={handleSaveUser}
                        className={classNames(
                          'd-block',
                          'd-sm-inline-block',
                          styles.btn
                        )}
                      >
                        <i
                          className={`${styles.icon} ${styles.iconCheckWhite}`}
                        />
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
