import { useState } from 'react'
import styles from './HeaderMain.module.css'
import classNames from 'classnames'
import { useOutsideClick } from '../../hooks/useOutsideClick'

import { useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import {
  Company,
  setCurrentCompany,
  setIsVisibleModalCompany,
} from '../../redux/reducers/company'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { companySelector } from '../../redux/selectors/company-selector'
import { getRole } from '../../utils/getRole'

const TITLE = {
  [PATH.DASHBOARD]: 'Dashboard',
  [PATH.COMPANIES]: 'Companies',
  [PATH.REPORTS]: 'Reports',
  [PATH.MY_REPORTS]: 'My Reports',
  [PATH.EMPLOYEES]: 'Employees',
  [PATH.CARS]: 'Cars',
  [PATH.PROFILE]: 'You account',
}

const { isAssistant, isDriver } = getRole()

function HeaderMain() {
  const dispatch = useDispatch<AppDispatch>()
  const { company, currentCompany } = useSelector(companySelector)

  const location = useLocation()

  const isCompaniesRoute = location.pathname !== PATH.COMPANIES

  const title = TITLE[location.pathname as keyof typeof TITLE]

  const [isSelectMenu, setIsSelectMenu] = useState(false)
  const ref = useOutsideClick(() => {
    setIsSelectMenu(false)
  })

  const handleButtonClick = () => {
    setIsSelectMenu((prev) => !prev)
  }

  const handleChangeCompany = (company: Company) => {
    dispatch(setCurrentCompany(company))

    setIsSelectMenu(false)
  }

  return (
    <div className="row align-items-center mb-3">
      <div className="col-12 col-sm-6 mb-3">
        <h2 className={`mb-0 ${styles.h2}`}>{title}</h2>
      </div>
      {!(isAssistant || isDriver) && (
        <div className="col-12 col-sm-6 mb-3" style={{ textAlign: 'right' }}>
          {isCompaniesRoute ? (
            <div
              ref={ref}
              className={`${styles.dropdown} d-block d-sm-inline-block`}
              style={{ textAlign: 'left' }}
            >
              <div
                onClick={handleButtonClick}
                className={classNames(
                  styles.btn,
                  styles.btnSmall,
                  styles.btnBlue,
                  styles.btnDropdown,
                  isSelectMenu && styles.btnDropdownActive
                )}
              >
                {currentCompany ? currentCompany.name : 'Select a Company'}
              </div>
              <div
                className={classNames(
                  styles.dropdownContent,
                  styles.dropdownSelect,
                  isSelectMenu && styles.dropdownContentActive
                )}
              >
                {company.map((item) => {
                  const { id, name } = item
                  return (
                    <div onClick={() => handleChangeCompany(item)} key={id}>
                      {name}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <a
              onClick={() => dispatch(setIsVisibleModalCompany(true))}
              className={`${styles.btn} ${styles.btnSmall}`}
            >
              + Add company
            </a>
          )}
        </div>
      )}
    </div>
  )
}

export default HeaderMain
