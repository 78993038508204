import authReducer from './reducers/auth'
import initReducer from './reducers/init'
import carsReducer from './reducers/cars'
import reportsReducer from './reducers/reports'
import usersReducer from './reducers/users'
import companyReducer from './reducers/company'
import reportFilter from './reducers/report-filter'

import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    init: initReducer,
    cars: carsReducer,
    reports: reportsReducer,
    users: usersReducer,
    company: companyReducer,
    reportFilter: reportFilter,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
