export function convertKeysToCamelCase<T>(item: Record<string, any>): T {
  const camelCaseObj: Record<string, any> = {}

  Object.keys(item).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
    camelCaseObj[camelCaseKey] = item[key]
  })

  return camelCaseObj as T
}
