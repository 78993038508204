import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'
import { getRole } from '../../utils/getRole'

export interface Company {
  id: number
  name: string
  logoDark: any
  logoWhite: any
  background: any
  domain: string
  active: true
  carsCount: number
  usersCount: number
  reportMinDate: string | null
}

export interface InitState {
  company: Company[]
  currentCompany: Company | null
  currentCompanyForEdit: Company | null
  isVisibleModalCompany: boolean
  isVisibleDeleteModalCompany: boolean
}

const initialState: InitState = {
  company: [],
  currentCompany: null,
  currentCompanyForEdit: null,
  isVisibleModalCompany: false,
  isVisibleDeleteModalCompany: false,
}
const { isOwner } = getRole()
export const fetchCompany = createAsyncThunk(
  'company/fetchCompany',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(`/${URL.company}`)
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchCurrentCompany = createAsyncThunk(
  'company/fetchCurrentCompany',
  async (_, thunkAPI) => {
    let retrievedObject

    const item = localStorage.getItem('init')
    if (item) {
      retrievedObject = JSON.parse(item)
    } else {
      // Handle the case where 'init' is not found in localStorage
    }

    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.company}/${retrievedObject.id}`
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDeleteCompany = createAsyncThunk(
  'users/fetchDeleteCompany',
  async (_, thunkAPI) => {
    const {
      company: { currentCompanyForEdit },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.company}/${currentCompanyForEdit?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

//CREATE COMPANY
interface CreateCompany {
  name: string
  domain: string
  active: boolean
  logoDark?: any
  logoWhite?: any
  background?: any
}
export const fetchCreateCompany = createAsyncThunk(
  'cars/fetchCreateCompany',
  async (
    { name, domain, active, logoDark, logoWhite, background }: CreateCompany,
    thunkAPI
  ) => {
    const formData = new FormData()

    const {
      company: { currentCompanyForEdit },
    } = thunkAPI.getState() as RootState

    formData.append('name', name)
    formData.append('domain', domain)
    formData.append('active', active ? '1' : '0')

    if (logoDark) {
      formData.append('logo_dark', logoDark, logoDark.name)
    }
    if (logoWhite) {
      formData.append('logo_white', logoWhite, logoWhite.name)
    }
    if (background) {
      formData.append('background', background, background.name)
    }

    if (currentCompanyForEdit?.id) {
      formData.set('_method', 'put')
    }
    try {
      const response = await axiosInstance.post(
        `/${URL.company}${currentCompanyForEdit?.id ? '/' + currentCompanyForEdit?.id : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCurrentCompany: (state, action) => {
      return { ...state, currentCompany: action.payload }
    },
    setCurrentCompanyForEdit: (state, action) => {
      return {
        ...state,
        currentCompanyForEdit: convertKeysToCamelCase(action.payload),
      }
    },
    setIsVisibleModalCompany: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalCompany: action.payload,
        currentCompanyForEdit: action.payload
          ? state.currentCompanyForEdit
          : null,
      }
    },
    setIsVisibleDeleteModalCompany: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalCompany: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state) => {
        return state
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        const newObj: any[] = action.payload.data.map((item: any) => {
          return convertKeysToCamelCase(item)
        })
        if (newObj.length) {
          return { ...state, company: newObj, currentCompany: newObj[0] }
        }

        return { ...state, company: newObj }
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })
      .addCase(fetchCurrentCompany.pending, (state) => {
        return state
      })
      .addCase(fetchCurrentCompany.fulfilled, (state, action) => {
        return {
          ...state,
          currentCompany: convertKeysToCamelCase(action.payload.data),
        }
      })
      .addCase(fetchCurrentCompany.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })

    // DELETE
    builder.addCase(fetchDeleteCompany.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteCompany.fulfilled, (state) => {
      toast.success('Saved!')

      return {
        ...state,
        isVisibleDeleteModalCompany: false,
        currentCompanyForEdit: null,
        company: state.company.filter(
          (item) => item.id !== state?.currentCompanyForEdit?.id
        ),
      }
    })
    builder.addCase(fetchDeleteCompany.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })

    builder.addCase(fetchCreateCompany.pending, (state) => {
      return state
    })
    builder.addCase(fetchCreateCompany.fulfilled, (state, action) => {
      toast.success('Saved!')

      if (isOwner) {
        const initCamelCase = convertKeysToCamelCase(action.payload)
        localStorage.setItem('init', JSON.stringify(initCamelCase))

        return {
          ...state,
          currentCompany: action.payload,
          isVisibleModalCompany: false,
        }
      } else {
        const init = localStorage.getItem('init')
        const initObject = init ? JSON.parse(init) : {}

        if (initObject.id === action.payload.id) {
          localStorage.setItem(
            'init',
            JSON.stringify(convertKeysToCamelCase(action.payload))
          )
        }

        const isCompanyExist = current(state).company.find(
          (item) => item.id === action.payload.id
        )

        if (isCompanyExist) {
          return {
            ...state,
            isVisibleModalCompany: false,
            company: state.company.map((item) =>
              item.id === action.payload.id
                ? convertKeysToCamelCase(action.payload)
                : item
            ),
            currentCompanyForEdit: null,
          }
        }
        return {
          ...state,
          company: [convertKeysToCamelCase(action.payload), ...state.company],
          currentCompanyForEdit: null,
          isVisibleModalCompany: false,
        }
      }
    })
    builder.addCase(fetchCreateCompany.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isVisibleModalCompany: false }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setCurrentCompany,
  setIsVisibleModalCompany,
  setIsVisibleDeleteModalCompany,
  setCurrentCompanyForEdit,
} = companySlice.actions

export default companySlice.reducer
