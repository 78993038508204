import React, { useEffect, useState } from 'react'

import styles from './ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchAddReports,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import classNames from 'classnames'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { getRole } from '../../../utils/getRole'

//@ts-ignore
import ModalImage from 'react-modal-image'
import { companySelector } from '../../../redux/selectors/company-selector'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
type FileArray = File[]

const formattedDate = (dateStr: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(dateStr)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month} ${year}` // '12 Sep 2023'
}

function ReportModal() {
  const { isAdmin, isAssistant } = getRole()
  const dispatch = useDispatch<AppDispatch>()

  const { cars } = useSelector(carsSelector)
  const { currentReport } = useSelector(reportsSelector)
  const { currentCompany } = useSelector(companySelector)

  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [truck, setTruck] = useState<string | undefined>(undefined)
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [expenses, setExpenses] = useState('')
  const [route, setRoute] = useState('')
  const [date, setDate] = useState('')

  const [photos, setPhotos] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])
  const [receiptRemove, setReceiptRemove] = useState<Array<number | string>>([])
  const [isTimeValid, setIsTimeValid] = useState(false)

  useEffect(() => {
    if (startTime && endTime) {
      if (startTime >= endTime) {
        setIsTimeValid(true)
      } else {
        setIsTimeValid(false)
      }
    }
  }, [endTime, startTime, currentReport])

  const handleStartTimeChange = (e: any) => {
    const newStartTime = e.target.value
    setStartTime(newStartTime)
  }

  const handleEndTimeChange = (e: any) => {
    const newEndTime = e.target.value
    setEndTime(newEndTime)
  }

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // Проверка на null перед использованием Array.from
    if (event.target.files) {
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  useEffect(() => {
    if (currentReport) {
      const [newDateStart, timeWithSeconds] =
        currentReport?.date_from.split(' ')
      const [hours, minutes] = timeWithSeconds.split(':')

      const [_, timeWithSecondsEnd] = currentReport?.date_to.split(' ')
      const [hoursEnd, minutesEnd] = timeWithSecondsEnd.split(':')

      const timeStart = hours + ':' + minutes
      const timeTo = hoursEnd + ':' + minutesEnd

      setStartTime(timeStart)
      setEndTime(timeTo)
      setDate(newDateStart)
      setExpenses(currentReport?.cost)
      setRoute(currentReport?.distance)
      setTruck(currentReport?.car?.id)
      setPhotos(currentReport?.receipts)
    }
  }, [])

  const handleChangeDate = (event: any) => {
    setDate(event.target.value)
  }

  const handleSendReport = () => {
    if (
      !isAssistant &&
      truck &&
      startTime &&
      endTime &&
      expenses &&
      route &&
      date
    ) {
      if (isAdmin) {
        dispatch(
          fetchAddReports({
            truck,
            startTime,
            endTime,
            expenses,
            route,
            date,
            photos: selectedPhotoForSend,
            id: currentReport?.id,
            receiptRemove,
          })
        )
      } else {
        dispatch(
          fetchAddReports({
            truck,
            startTime,
            endTime,
            expenses,
            route,
            date,
            photos: selectedPhotoForSend,
            id: currentReport?.id,
            receiptRemove,
          })
        )
      }
    }

    if (isAssistant && truck && startTime && endTime && date) {
      dispatch(
        fetchAddReports({
          truck,
          startTime,
          endTime,
          date,
          id: currentReport?.id,
        })
      )
    }
  }

  //@ts-ignore
  useSelector(() => {}, [selectedPhoto])

  const handleDeletePhoto = (index: number) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((_, i) => i !== index)
    )
  }

  const handleDeleteSaved = (idImage: number | string) => {
    setReceiptRemove((prev) => [...prev, idImage])
    //@ts-ignore
    setPhotos(photos?.filter((item) => item?.id !== idImage))
  }

  useEffect(() => {
    if (isAssistant || currentReport?.user?.role === 'assistant') {
      if (truck && startTime && endTime && date && !isTimeValid) {
        setIsDisabledButton(false)
      } else {
        setIsDisabledButton(true)
      }
    } else {
      if (
        truck &&
        startTime &&
        endTime &&
        expenses &&
        route &&
        date &&
        !isTimeValid
      ) {
        setIsDisabledButton(false)
      } else {
        setIsDisabledButton(true)
      }
    }
  }, [date, endTime, expenses, isTimeValid, route, startTime, truck])

  useClearBodyNoScrollClass()

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalReport(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(`${isAdmin ? '' : 'mb-5'} ${styles.h3}}`)}
            >
              {currentReport ? 'Edit daily report' : 'New daily report'}
            </h3>

            {!isAdmin ? (
              <div style={{ textAlign: 'center' }} className="mb-3">
                <div className="report-date" id="report_date_input_wrapper">
                  <input
                    min={currentCompany?.reportMinDate || undefined}
                    max={new Date().toISOString().split('T')[0]}
                    className={styles.date}
                    type="date"
                    value={date}
                    onChange={handleChangeDate}
                    pattern="\d{4}-\d{2}-\d{2}"
                  />
                </div>
              </div>
            ) : (
              <div className={styles.title} style={{ textAlign: 'center' }}>
                <b>{formattedDate(date)}</b>/{' '}
                <span> {currentReport?.user?.name}</span>
              </div>
            )}

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-12 col-sm-6 mb-3">
                  <small className="d-block mb-1">Truck</small>
                  <select
                    value={truck}
                    onChange={(e) => setTruck(e.target.value)}
                    required
                  >
                    <option value="">Select truck</option>
                    {cars.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.name}${!item.active ? ' (disabled)' : ''}`}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-6 col-sm-3 mb-3">
                  <small className="d-block mb-1">Start time</small>
                  <input
                    value={startTime}
                    onChange={handleStartTimeChange}
                    required
                    id="time_start"
                    name="time_start"
                    type="time"
                    step="60"
                  />
                  {isTimeValid && (
                    <small
                      className={classNames(styles.timeZone, styles.errorText)}
                    >
                      <b>Start time</b> must be less than <b>End time</b>
                    </small>
                  )}
                </div>

                <div className="col-6 col-sm-3 mb-3">
                  <small className="d-block mb-1">End time</small>
                  <input
                    value={endTime}
                    onChange={handleEndTimeChange}
                    required
                    id="time_end"
                    name="time_end"
                    type="time"
                    step="60"
                  />
                </div>

                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-6 col-sm-3 mb-3">
                      <small className="d-block mb-1">Expenses, EUR</small>
                      <input
                        value={expenses}
                        onChange={(e) => {
                          setExpenses(e.target.value)
                        }}
                        required
                        id="new_expenses"
                        name="expenses"
                        type="text"
                      />
                    </div>
                  )}

                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-6 col-sm-3 mb-3">
                      <small className="d-block mb-1">Route, km</small>
                      <input
                        value={route}
                        onChange={(e) => {
                          setRoute(e.target.value)
                        }}
                        required
                        id="new_route"
                        name="route"
                        type="text"
                      />
                    </div>
                  )}
                {!isAssistant &&
                  (currentReport
                    ? currentReport?.user?.role !== 'assistant'
                    : true) && (
                    <div className="col-12 col-sm-6">
                      <small className="d-block mb-1">Expenses photos</small>
                      <div
                        id="load_gallery"
                        className={classNames(
                          styles.btn,
                          styles.btnBlueOutline,
                          styles.btnSmall,
                          styles.btnWide,
                          styles.loadGallery
                        )}
                      >
                        + Photos
                        <input
                          multiple={true}
                          onChange={handlePhotoChange}
                          type="file"
                          name="report_gallery"
                          id="report_gallery"
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div
                id="load_gallery_images"
                className={classNames(`mt-3 ${styles.loadGalleryImages}}`)}
                style={{ textAlign: 'center' }}
              >
                <div className={styles.loadGalleryPhoto}>
                  {photos?.map(
                    (photo: {
                      id: string
                      receipt: { sm: string; md: string }
                    }) => {
                      return (
                        <div className={styles.reportsImgContainer}>
                          <ModalImage
                            key={photo.id}
                            className={`${styles.reportsImg} mb-1`}
                            small={
                              photo.receipt.sm || 'img/cars/no-car-photo.svg'
                            }
                            medium={
                              photo.receipt.md || 'img/cars/no-car-photo.svg'
                            }
                            alt="User Photo"
                          />

                          <span
                            onClick={() => handleDeleteSaved(photo.id)}
                            className={styles.del}
                          >
                            ×
                          </span>
                        </div>
                      )
                    }
                  )}
                  {selectedPhoto?.map((item, index) => {
                    return (
                      <div className={styles.reportsImgContainer}>
                        <ModalImage
                          key={index}
                          className={`${styles.reportsImg} mb-1`}
                          small={item || 'img/cars/no-car-photo.svg'}
                          medium={item || 'img/cars/no-car-photo.svg'}
                          alt="User Photo"
                        />
                        <span
                          onClick={() => handleDeletePhoto(index)}
                          className={styles.del}
                        >
                          ×
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                ></div>
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    onClick={handleSendReport}
                    className={classNames(
                      `${styles.btn} d-block d-sm-inline-block}`
                    )}
                    disabled={isDisabledButton}
                  >
                    <i
                      className={classNames(styles.icon, styles.iconCheckWhite)}
                    />
                    {currentReport ? 'Save' : 'Send'}
                  </button>
                </div>
                <div
                  className="col-12 col-sm-4 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalReport(false))}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline,
                      'd-block',
                      'd-sm-inline-block'
                    )}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportModal
