import { useEffect, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { getRole } from '../../utils/getRole'

import styles from './AsideMenu.module.css'
import classNames from 'classnames'

const LINKS = [
  // {
  //     path: PATH.DASHBOARD,
  //     iconStyle: "icon icon-home-white",
  //     title: "Dashboard",
  // },
  {
    path: PATH.COMPANIES,
    iconStyle: 'iconFlagWhite',
    title: 'Companies',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'Reports',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.MY_REPORTS,
    iconStyle: 'iconReportsWhite',
    title: 'My Reports',
    roles: ['isDriver', 'isAssistant'],
  },
  {
    path: PATH.EMPLOYEES,
    iconStyle: 'iconUsersWhite',
    title: 'Employees',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.CARS,
    iconStyle: 'iconTruckWhite',
    title: 'Cars',
    roles: ['isAdmin', 'isOwner'],
  },
  {
    path: PATH.PROFILE,
    iconStyle: 'iconProfileWhite',
    title: 'You account',
    roles: ['isAdmin', 'isOwner', 'isDriver', 'isAssistant'],
  },
] as const

function AsideMenu() {
  const roles = getRole()
  const [retrievedObject, setRetrievedObject] = useState<any>()

  const item = localStorage.getItem('init')

  useEffect(() => {
    if (item) {
      setRetrievedObject(JSON.parse(item))
    }
  }, [item])
  const location = useLocation()

  return (
    <div>
      <div className={styles.accountLeftPanel}>
        <NavLink to="/profile" className={styles.logo}>
          <img className={styles.img} src={retrievedObject?.logoWhite?.original} />
        </NavLink>
        <div className={classNames(styles.leftMenuAccount, styles.small)}>
          {LINKS.filter((link) => link.roles.some((role) => roles[role])).map(
            ({ title, path, iconStyle }) => (
              <NavLink
                key={path}
                className={classNames(
                  styles.navLink,
                  location.pathname === path && styles.active
                )}
                to={path}
              >
                <i className={`${styles.icon} ${styles[iconStyle]}`} />{' '}
                <span>{title}</span>
              </NavLink>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default AsideMenu
