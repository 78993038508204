import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'
import { toast } from 'react-toastify'

export interface InitState {
  isPending: boolean
  initCompany: {
    id: number
    name: string
    logoDark: string[]
    logoWhite: string[]
    background: string[]
    domain?: string
    active?: true
    carsCount?: number
    usersCount?: number
  } | null
}

const initialState: InitState = {
  initCompany: null,
  isPending: false,
}

export const fetchInit = createAsyncThunk(
  'init/fetchInit',
  async (_, thunkAPI) => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('init')

    try {
      const response: AxiosResponse = await axiosInstance.get(`/${URL.init}`)
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInit.pending, (state, action) => {
        return { ...state, isPending: true }
      })
      .addCase(fetchInit.fulfilled, (state, action) => {
        const initCamelCase = convertKeysToCamelCase<InitState['initCompany']>(
          action.payload.data
        )
        localStorage.setItem('init', JSON.stringify(initCamelCase))
        return { ...state, initCompany: initCamelCase, isPending: false }
      })
      .addCase(fetchInit.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPending: false }
      })
  },
})

export default initSlice.reducer
