import { useEffect, useState } from 'react'
import styles from './ReportsCalendar.module.css'
import { DateRange, DefinedRange, Range } from 'react-date-range'

import ru from 'date-fns/locale/ru' // Импорт русской локали
import { startOfDay, endOfDay } from 'date-fns'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import { AppDispatch } from '../../../redux/store'
import { useDispatch } from 'react-redux'
import { fetchReports } from '../../../redux/reducers/reports'
import classNames from 'classnames'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { DEFINEDS, staticRangesArray } from '../../../constants/date'

function ReportsCalendar() {
  const dispatch = useDispatch<AppDispatch>()

  const [calendarRange, setCalendarRange] = useState<Array<Range>>([
    {
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
      key: 'selection',
    },
  ])

  const [clickCount, setClickCount] = useState(0)

  useEffect(() => {
    dispatch(
      fetchReports({
        //@ts-ignore

        dateFrom: startOfDay(calendarRange[0].startDate),
        //@ts-ignore
        dateTo: endOfDay(calendarRange[0].endDate),
      })
    )
  }, [calendarRange])

  const [isOpen, setIsOpen] = useState(false)

  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (clickCount >= 2) {
      setIsOpen(false)
      setClickCount(0)
    }
  }, [clickCount])

  return (
    <>
      <div
        className={classNames(
          `col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 account-left-col, ${styles.datePicker}`
        )}
      >
        <div className="row">
          <div
            className="col-8 col-sm-9 col-md-12 pr-1"
            style={{ textAlign: 'left' }}
          >
            <div className={styles.calendar}>
              <div style={{ marginBottom: '3px' }}>Dates</div>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  setCalendarRange([item.selection])
                }}
                inputRanges={[]}
                rangeColors={['#11e651']}
                ranges={calendarRange}
                staticRanges={staticRangesArray}
              />

              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  setCalendarRange([item.selection])
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRange}
                months={2}
              />
            </div>
          </div>
        </div>
      </div>
      {<div className={styles.isMobileMargin} />}
      <div className={styles.dateRangeMobile} ref={ref}>
        <div className={styles.flexInput}>
          <input
            type="text"
            readOnly
            //@ts-ignore
            value={`${calendarRange[0].startDate.toLocaleDateString()} - ${calendarRange[0].endDate.toLocaleDateString()}`}
            onClick={() => setIsOpen(!isOpen)}
            className={styles.dateInput}
          />
        </div>

        {isOpen && (
          <>
            <div className={styles.calendar}>
              <DefinedRange
                className={styles.range}
                onChange={(item) => {
                  setClickCount(2)
                  setCalendarRange([item.selection])
                }}
                inputRanges={[]}
                rangeColors={['#11e651']}
                ranges={calendarRange}
                staticRanges={staticRangesArray}
              />
              <DateRange
                className={styles.dateRange}
                rangeColors={['#0f6dba']}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                editableDateInputs={true}
                onChange={(item) => {
                  setClickCount((prev) => (prev += 1))
                  setCalendarRange([item.selection])
                }}
                moveRangeOnFirstSelection={false}
                ranges={calendarRange}
                months={2}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ReportsCalendar
