import { useEffect } from 'react'

export const useClearBodyNoScrollClass = () => {
  useEffect(() => {
    document.body.classList.add('noScroll')

    return () => {
      document.body.classList.remove('noScroll')
    }
  }, [])
}
