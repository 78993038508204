import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'

interface Car {
  id: number
  name: string
  active: boolean
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
}

export interface CarsState {
  currentCar: Car | null
  cars: Array<Car>
  isVisibleModalCar: boolean
  isVisibleDeleteModalCar: boolean
  isPendingGetCars: boolean
}

const initialState: CarsState = {
  currentCar: null,
  isVisibleModalCar: false,
  isVisibleDeleteModalCar: false,
  cars: [],
  isPendingGetCars: true,
}

// FOR OWNERS
export const fetchCars = createAsyncThunk(
  'cars/fetchCars',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(`/${URL.cars}`)

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

// FOR ADMINS
export const fetchCarsForAdmins = createAsyncThunk(
  'cars/fetchCarsForAdmins',
  async (id: number | string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.company}/${id}/car`
      )

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

type FetchAuth = {
  carName: string
  selectedImage: any
  isActive: boolean
  id?: number
  currentCompanyId?: string | number
}

export const fetchEditCar = createAsyncThunk(
  'cars/fetchEditCar',
  async (
    { carName, selectedImage, isActive, id = undefined }: FetchAuth,
    thunkAPI
  ) => {
    const formData = new FormData()

    formData.append('name', carName)
    formData.append('active', isActive ? '1' : '0')

    if (selectedImage) {
      formData.append('image', selectedImage, selectedImage.name)
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.cars}${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchAddCarForAdmin = createAsyncThunk(
  'cars/fetchAddCarForAdmin',
  async (
    { carName, selectedImage, isActive, currentCompanyId }: FetchAuth,
    thunkAPI
  ) => {
    const formData = new FormData()

    formData.append('name', carName)
    formData.append('active', isActive ? '1' : '0')

    if (selectedImage) {
      formData.append('image', selectedImage, selectedImage.name)
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.company}/${currentCompanyId}/car`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteCar = createAsyncThunk(
  'cars/fetchDeleteCar',
  async (_, thunkAPI) => {
    const {
      cars: { currentCar },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.cars}/${currentCar?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setCurrentCar: (state, action) => {
      return { ...state, currentCar: action.payload }
    },
    setIsVisibleModalCar: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalCar: action.payload,
        currentCar: action.payload ? state.currentCar : null,
      }
    },
    setIsVisibleDeleteModalCar: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalCar: action.payload,
    }),
  },
  extraReducers: (builder) => {
    //FOR OWNERS
    builder.addCase(fetchCars.pending, (state) => {
      return { ...state, isPendingGetCars: true }
    })
    builder.addCase(fetchCars.fulfilled, (state, action) => {
      return { ...state, cars: action.payload.data, isPendingGetCars: false }
    })
    builder.addCase(fetchCars.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetCars: false }
    })
    //FOR AMINS
    builder.addCase(fetchCarsForAdmins.pending, (state) => {
      return { ...state, isPendingGetCars: true }
    })
    builder.addCase(fetchCarsForAdmins.fulfilled, (state, action) => {
      return { ...state, isPendingGetCars: false, cars: action.payload.data }
    })
    builder.addCase(fetchCarsForAdmins.rejected, (state, action) => {
      toast.error(action.payload as string)
      return { ...state, isPendingGetCars: false }
    })

    builder.addCase(fetchEditCar.pending, (state) => {
      return state
    })
    builder.addCase(fetchEditCar.fulfilled, (state, action) => {
      toast.success('Saved!')

      const isCarExist = current(state).cars.find(
        (item) => item.id === action.payload.id
      )

      if (isCarExist) {
        return {
          ...state,
          isVisibleModalCar: false,
          cars: state.cars.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        }
      }
      return {
        ...state,

        cars: [action.payload, ...state.cars],
        isVisibleModalCar: false,
      }
    })
    builder.addCase(fetchEditCar.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })

    // FOR ADMIN
    builder.addCase(fetchAddCarForAdmin.pending, (state) => {
      return state
    })
    builder.addCase(fetchAddCarForAdmin.fulfilled, (state, action) => {
      toast.success('Saved!')

      return {
        ...state,

        cars: [action.payload, ...state.cars],
        isVisibleModalCar: false,
      }
    })
    builder.addCase(fetchAddCarForAdmin.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })

    builder.addCase(fetchDeleteCar.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteCar.fulfilled, (state, action) => {
      toast.success('Saved!')

      return {
        ...state,
        isVisibleDeleteModalCar: false,
        currentCar: null,
        cars: state.cars.filter((car) => car.id !== state?.currentCar?.id),
      }
    })
    builder.addCase(fetchDeleteCar.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
  },
})

export const {
  setCurrentCar,
  setIsVisibleModalCar,
  setIsVisibleDeleteModalCar,
} = carsSlice.actions

export default carsSlice.reducer
