import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import AppRoutes from './AppRoutes'
import Header from './components/header/Header'
import AsideMenu from './components/aside-menu/AsideMenu'
import { PATH } from './constants/path'
import Auth from './pages/auth/Auth'

import './App.css'
import { useEffect } from 'react'
import AddCarModal from './components/modal/car-modal/AddCarModal'
import AddCompanyModal from './components/modal/company-modal/AddCompanyModal'
import { useDispatch, useSelector } from 'react-redux'
import { carsSelector } from './redux/selectors/cars-selector'
import { getCookie } from './utils/getCookies'
import { usersSelector } from './redux/selectors/users-selector'
import {
  fetchDeleteUser,
  setIsVisibleDeleteModalUser,
} from './redux/reducers/users'
import {
  fetchDeleteCar,
  setIsVisibleDeleteModalCar,
} from './redux/reducers/cars'
import DeleteModal from './components/modal/DeleteModal'
import AddUserModal from './components/modal/user-modal/AddUserModal'
import {
  fetchCompany,
  fetchCurrentCompany,
  fetchDeleteCompany,
  setIsVisibleDeleteModalCompany,
} from './redux/reducers/company'
import { AppDispatch } from './redux/store'
import { getRole } from './utils/getRole'
import { companySelector } from './redux/selectors/company-selector'
import Footer from './components/footer/Footer'
import ReportModal from './components/modal/report-modal/ReportModal'
import { reportsSelector } from './redux/selectors/reports-selector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ChangeEmailModal from './components/modal/change-email-modal/ChangeEmailModal'
import { authSelector } from './redux/selectors/auth-selector'
import ResetPasswordModal from './pages/reset-password/ResetPassword'
import {
  fetchDeleteReport,
  setIsVisibleDeleteModalReport,
} from './redux/reducers/reports'

const App = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { isVisibleModalCar, isVisibleDeleteModalCar } =
    useSelector(carsSelector)
  const { isVisibleModalUser, isVisibleDeleteModalUser } =
    useSelector(usersSelector)
  const { isVisibleModalCompany, isVisibleDeleteModalCompany } =
    useSelector(companySelector)
  const { isVisibleModalChangeEmail } = useSelector(authSelector)
  const { isVisibleModalReport, isVisibleDeleteModalReport } =
    useSelector(reportsSelector)

  useEffect(() => {
    const { isAdmin, hasRole } = getRole()

    if (hasRole) {
      if (isAdmin) {
        dispatch(fetchCompany())
      } else {
        dispatch(fetchCurrentCompany())
      }
    }
  }, [])

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          {!getCookie('auth') && (
            <Route exact path={PATH.AUTH} component={Auth} />
          )}
          <Route
            exact
            path={PATH.RESET_PASSWORD}
            component={ResetPasswordModal}
          />
          <Route
            render={() =>
              getCookie('auth') ? (
                <div className="container-header">
                  <AsideMenu />
                  <div className="container-main">
                    <Header />
                    <AppRoutes />
                  </div>
                </div>
              ) : (
                <Redirect to={PATH.AUTH} />
              )
            }
          />
        </Switch>
      </Router>
      {isVisibleModalCar && <AddCarModal />}
      {isVisibleModalUser && <AddUserModal />}
      {isVisibleModalCompany && <AddCompanyModal />}
      {isVisibleModalReport && <ReportModal />}
      {isVisibleModalChangeEmail && <ChangeEmailModal />}

      {isVisibleDeleteModalCar && (
        <DeleteModal
          title="Car"
          onConfirm={fetchDeleteCar()}
          onCancel={setIsVisibleDeleteModalCar(false)}
        />
      )}
      {isVisibleDeleteModalUser && (
        <DeleteModal
          title="User"
          onConfirm={fetchDeleteUser()}
          onCancel={setIsVisibleDeleteModalUser(false)}
        />
      )}
      {isVisibleDeleteModalCompany && (
        <DeleteModal
          title="Company"
          onConfirm={fetchDeleteCompany()}
          onCancel={setIsVisibleDeleteModalCompany(false)}
        />
      )}

      {isVisibleDeleteModalReport && (
        <DeleteModal
          title="Report"
          onConfirm={fetchDeleteReport()}
          onCancel={setIsVisibleDeleteModalReport(false)}
        />
      )}
      <Footer />
      <ToastContainer />
    </ErrorBoundary>
  )
}

export default App
