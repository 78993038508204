import React, { useEffect, useState } from 'react'

import styles from './Companies.module.css'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import {
  Company,
  setCurrentCompanyForEdit,
  setIsVisibleDeleteModalCompany,
  setIsVisibleModalCompany,
} from '../../redux/reducers/company'
import { companySelector } from '../../redux/selectors/company-selector'
import { getRole } from '../../utils/getRole'
import HeaderMain from '../../components/header-main/HeaderMain'
import { convertKeysToCamelCase } from '../../utils/objKeysToCamelCase'

const { isAdmin, isOwner } = getRole()
function Companies() {
  const dispatch = useDispatch<AppDispatch>()
  const [companyForShow, setCompanyForShow] = useState<Company[]>()

  const { company, currentCompany } = useSelector(companySelector)

  const handleEditCompany = (item: Company) => {
    dispatch(setCurrentCompanyForEdit(item))
    dispatch(setIsVisibleModalCompany(true))
  }

  const handleDeleteCompany = (item: Company) => {
    dispatch(setCurrentCompanyForEdit(item))
    dispatch(setIsVisibleDeleteModalCompany(true))
  }
  useEffect(() => {
    if (isAdmin) {
      setCompanyForShow(company)
    }
    if (isOwner && currentCompany) {
      setCompanyForShow([convertKeysToCamelCase(currentCompany)])
    }
  }, [company, currentCompany])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
              {companyForShow &&
                companyForShow?.map((item: Company) => {
                  const {
                    active,
                    usersCount,
                    carsCount,
                    name,
                    logoDark,
                    logoWhite,
                  } = item
                  return (
                    <div
                      className={classNames(
                        styles.bgWhite,
                        styles.border5,
                        styles.bxShadow,
                        'pt-2 pb-2 pl-3 pr-3 bx-shadow mb-3'
                      )}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-3">
                          <a className={styles.companyImg}>
                            <img
                              onClick={() => handleEditCompany(item)}
                              src={
                                logoDark?.original ||
                                logoWhite?.original ||
                                'img/company/no-company.png'
                              }
                            />
                          </a>
                        </div>
                        <div className="col-8 col-sm-6 mt-1">
                          <div className={classNames(styles.fontBig, 'mb-2')}>
                            <a onClick={() => handleEditCompany(item)}>
                              <b>{name}</b>
                            </a>
                          </div>
                          <div className={styles.small}>
                            {active ? (
                              <span className={classNames('mr-3', styles.span)}>
                                <i
                                  className={classNames(
                                    styles.icon,
                                    styles.iconVerified2,
                                    styles.icon20
                                  )}
                                />
                                <span> Active</span>
                              </span>
                            ) : (
                              <span
                                className={classNames(
                                  styles.span,
                                  styles.small,
                                  styles.colorRed
                                )}
                              >
                                Disabled
                              </span>
                            )}
                            <span className={classNames('mr-3', styles.span)}>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconTruck,
                                  styles.icon20
                                )}
                              />{' '}
                              <span>{carsCount}</span>
                            </span>
                            <span className={classNames('mr-3', styles.span)}>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconUsers,
                                  styles.icon20
                                )}
                              />{' '}
                              <span>{usersCount}</span>
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-4 col-sm-3 mt-1"
                          style={{ textAlign: 'right' }}
                        >
                          <div
                            onClick={() => handleEditCompany(item)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall,
                              styles.btnIcon,
                              styles.btnBlueOutline
                            )}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconEdit
                              )}
                            />
                          </div>
                          {/* <div
                          onClick={() => handleDeleteCompany(item)}
                          className={classNames(
                            styles.btn,
                            styles.btnSmall,
                            styles.btnIcon,
                            styles.btnOutline
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconDeleteRed
                            )}
                          />
                        </div> */}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Companies
